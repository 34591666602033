import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { useForm, Controller } from 'react-hook-form'

import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import InputGroup from 'react-bootstrap/InputGroup'

import { useSearchString } from '../lib/searchState'
import { currentLocalDatetimeString } from '../lib/utils'

import { useUpdateEventWithPhotos } from 'client/src/queries'

import { validImageFileVld, PhotoInputField } from './FormFields'
import CreateProgressModal from './CreateProgressModal'
import EquipmentPicker from './EquipmentPicker'


const CreateIdentVld = z.object({
  idAt: z.date(),
  equipmentId: z.string().min(5, 'Equipment code is required'),
  idPhoto: validImageFileVld,
  pctR134: z.number().min(0).max(100),
  pctR12: z.number().min(0).max(100),
  pctR22: z.number().min(0).max(100),
  pctHc: z.number().min(0).max(100),
  pctAir: z.number().min(0).max(100),
  notes: z.string(),
}).superRefine((ident, ctx) => {
  const gasSum = ident.pctR134 + ident.pctR12 + ident.pctR22 + ident.pctHc + ident.pctAir
  const isValid = gasSum >= 99.9 && gasSum <= 100.1
  if (isValid) return true
  for (const key of ['pctR134', 'pctR12', 'pctR22', 'pctHc', 'pctAir']) {
    ctx.addIssue({
      message: 'Gas percentages must add up to 100%',
      path: [key],
      code: z.ZodIssueCode.custom,
    })
  }
})

type CreateIdentT = z.infer<typeof CreateIdentVld>

function GasIdentForm() {
  const { t } = useTranslation()

  const [ equipmentId, _setEquipmentId ] = useSearchString('', 'eqId')

  const { progress, mutate, reset: resetSubmit } = useUpdateEventWithPhotos()

  const {
    register, control, watch, handleSubmit, setValue, formState: { errors }, reset: resetForm,
  } = useForm<CreateIdentT>({
    resolver: zodResolver(CreateIdentVld),
  })

  const gasPcts = watch(['pctR134', 'pctR12', 'pctR22', 'pctHc', 'pctAir'])
  const gasSum = gasPcts.reduce((a, b) => a + b, 0)
  useEffect(() => {
    if (equipmentId) {
      setValue('equipmentId', equipmentId, { shouldTouch: true })
    }
  }, [equipmentId, setValue])

  return (<>
    <CreateProgressModal progress={progress} mutate={mutate} reset={resetSubmit} resetForm={resetForm} />
    <Row><Col className="mx-auto" md="8" lg="6">
      <h2>{ t('Gas Identification') }</h2>

      <Form onSubmit={ handleSubmit((data) => mutate.mutate({ event: data, type: 'ident' })) }>
        <Form.Group controlId="idAt" className="mb-3">
          <Form.Label>{ t('Date the QC was performed') }</Form.Label>
          <Form.Control
            type="datetime-local"
            max={ currentLocalDatetimeString() }
            {...register('idAt', { valueAsDate: true })}
            isInvalid={ 'idAt' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.idAt?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label htmlFor="equipmentId">
            { t('Equipment Code') }
          </Form.Label>
          <Controller
            control={ control }
            name="equipmentId"
            render={
              (
                { field: { onChange, onBlur, value }, fieldState: { invalid, error } }
              ) => (<>
                < EquipmentPicker
                  id="equipmentId"
                  placeholder={ t('Select equipment code...') }
                  clearButton={ true }
                  selected={ value ? [value] : [] }
                  onChange={ (ids: string[]) => onChange(ids[0]) }
                  onBlur={ onBlur }
                  types={['cylinder']}
                  isInvalid={ invalid }
                />
                <div className={ `invalid-feedback ${ invalid ? 'd-block' : '' }` }>
                  { t(error?.message || 'Error') }
                </div>
              </>)
            }
          />
        </Form.Group>

        <h3>{ t('Analysis results')}</h3>
        <p>Current total: {gasSum.toFixed(2)}%</p>
        <InputGroup  className="mb-3">
          <InputGroup.Text>{ t('R134A') }</InputGroup.Text>
          <Form.Control
            {...register('pctR134', { valueAsNumber: true} )}
            type="number"
            step="0.01"
            defaultValue="0"
            isInvalid={ 'pctR134' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.pctR134?.message || 'Error') }
          </Form.Control.Feedback>
        </InputGroup>
        <InputGroup  className="mb-3">
          <InputGroup.Text>{ t('R12') }</InputGroup.Text>
          <Form.Control
            {...register('pctR12', { valueAsNumber: true} )}
            type="number"
            step="0.01"
            defaultValue="0"
            isInvalid={ 'pctR12' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.pctR12?.message || 'Error') }
          </Form.Control.Feedback>
        </InputGroup>
        <InputGroup  className="mb-3">
          <InputGroup.Text>{ t('R22') }</InputGroup.Text>
          <Form.Control
            {...register('pctR22', { valueAsNumber: true} )}
            type="number"
            step="0.01"
            defaultValue="0"
            isInvalid={ 'pctR22' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.pctR22?.message || 'Error') }
          </Form.Control.Feedback>
        </InputGroup>
        <InputGroup  className="mb-3">
          <InputGroup.Text>{ t('HC') }</InputGroup.Text>
          <Form.Control
            {...register('pctHc', { valueAsNumber: true} )}
            type="number"
            step="0.01"
            defaultValue="0"
            isInvalid={ 'pctHc' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.pctHc?.message || 'Error') }
          </Form.Control.Feedback>
        </InputGroup>
        <InputGroup  className="mb-3">
          <InputGroup.Text>{ t('Air') }</InputGroup.Text>
          <Form.Control
            {...register('pctAir', { valueAsNumber: true} )}
            type="number"
            step="0.01"
            defaultValue="0"
            isInvalid={ 'pctAir' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.pctAir?.message || 'Error') }
          </Form.Control.Feedback>
        </InputGroup>

        <Form.Group controlId="notes" className="mb-3">
          <Form.Label>
            { t('Additional Note') }
            <br />
            <small>{ t('Anything else to add?') }</small>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={ t('Enter notes') }
            {...register('notes')}
            isInvalid={ 'notes' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.notes?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="idPhoto" className="mb-3">
          <Form.Label>{ t('Supporting Photo or Video') }</Form.Label>
          <PhotoInputField control={ control } name="idPhoto" />
        </Form.Group>

        <Button variant="primary" type="submit">
          { t('Submit') }
        </Button>
      </Form>
    </Col></Row>
  </>);
}

export default GasIdentForm;
