import { useParams } from 'react-router-dom';
import { useShipmentDetail } from 'client/src/queries';
import DateStr from './DateStr'
import { EventCard, equipmentLink } from './EventCards'
import type { EventCardProps } from './EventCards'
import EventImage from './EventImage';

function ShipmentDetail() {
  const {eventId} = useParams()
  const { isLoading, error, data } = useShipmentDetail(eventId)

  if (isLoading) {
    return (<div>Loading...</div>)
  }

  if (error) {
    return (<div>Error: { (error as Error).message }</div>)
  }

  const shipment = data.shipment
  const moves = shipment.moves || []
  const moveLinks = moves.map((move) => {
    return equipmentLink(move.eqStickerCode, move.id, 'Equipment')
  })

  const items: EventCardProps['items'] = [
    { key: 'originPerson', child: <>{ shipment.originPerson }</> },
    { key: 'destPerson', child: <>{ shipment.destPerson }</> },
    ...moveLinks,
    {
      child: <EventImage id={ shipment.handoverPhoto } label="Handover Photo" />,
    },
  ]

  return (
    <div>
      <h1>Shipment</h1>
      <DateStr date={ shipment.shipmentAt } className="text-secondary" />
      <EventCard eventId={shipment.id} items={items} />
    </div>
  )
}

export default ShipmentDetail;

