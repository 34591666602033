import { ReactNode, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { compact } from 'lodash-es'

import ListGroup from 'react-bootstrap/ListGroup'
import type { ListGroupItemProps } from 'react-bootstrap/ListGroupItem'
import Table from 'react-bootstrap/Table'

import { Link } from 'react-router-dom';

import DateStr from './DateStr'
import EventImage from './EventImage';

import type { EquipmentQc } from '../../../api-rec/src/db/equipmentQcs'
import type { EquipmentVacuum } from '../../../api-rec/src/db/equipmentVacuums'
import type { EquipmentMove } from '../../../api-rec/src/db/equipmentMoves'
import type { Shipment } from '../../../api-rec/src/db/shipments'
import type { EquipmentWeight } from '../../../api-rec/src/db/equipmentWeights'
import type { EquipmentBorrow } from '../../../api-rec/src/db/equipmentBorrows'
import type { GasTransfer } from '../../../api-rec/src/db/gasTransfers'
import type { GasRecovery } from '../../../api-rec/src/db/gasRecoveries'
import type { GasDestruction } from '../../../api-rec/src/db/gasDestructions'
import type { GasIdent } from '../../../api-rec/src/db/gasIdents'

type EventCardItem = {
  props?: ListGroupItemProps & (ComponentProps<'a'> | ComponentProps<typeof Link>),
  key?: string,
  child: ReactNode,
}

type EventCardProps = {
  hideCylinder?: boolean,
  eventId?: string,
  items: Array<EventCardItem | null>
}

const SheetGids = {
  'in_borrow': 1894326541,
  'in_consumable': 2140757628,
  'in_destruction': 1219247081,
  'in_equipment': 1721174551,
  'in_feeder': 827379231,
  'in_identifier': 287430899,
  'in_move': 1865036735,
  'in_offset': 1225886583,
  'in_payment': 700739377,
  'in_project': 1827381180,
  'in_qc': 1914220377,
  'in_recovery': 1670037247,
  'in_transfer-s': 1029781537,
  'in_transfer-x': 375103568,
  'in_user': 375113881,
  'in_vakum': 1835519369,
  'in_weight': 368728370,
  'db_borrow': 1142969581,
  'db_consumable': 157941386,
  'db_destruction': 454878057,
  'db_credit_sales': 872380266,
  'db_equipment': 266302473,
  'db_feeder': 891911100,
  'db_gas': 1375892025,
  'db_identifier': 1838311090,
  'db_move': 2105231595,
  'db_partner': 1165862361,
  'db_payment': 1744214881,
  'db_payment-chain': 1681253459,
  'db_project': 1934248963,
  'db_purchase': 275104466,
  'db_qc': 1457356219,
  'db_recovery': 0,
  'db_series': 1242783145,
  'db_stickers': 736968366,
  'db_transfer': 1388905893,
  'db_transfer-chain': 1240687936,
  'db_tareweight': 522796821,
  'db_user': 81199561,
  'db_vakum': 1227158826,
  'db_weight': 302667686,
  'equipment_history': 290477576,
  'sort_eqp': 673383006,
  'dummy_id': 1557791803,
  'dummy_ledger': 969959860,
  'ledger': 312885312,
  'temp_transfer': 1015361363,
  'copy of db_transfer1': 1252359406,
}

function spreadsheetIdLink(event: { spreadsheetRowId: string }, sheetName: keyof typeof SheetGids): EventCardItem {
  if (!event.spreadsheetRowId) return null
  const gid = SheetGids[sheetName]
  const url = `https://docs.google.com/spreadsheets/d/1ifS2hazossiwPYVJfiGA55SV30lc3w61ICrscPh2TJA/edit#gid=${gid}`
  return {
    props: { action: true, href: url, target: '_blank' },
    key: 'Sheet ID',
    child: <>{ event.spreadsheetRowId } in {sheetName }</>,
  }
}

function equipmentLink(eqStickerCode: string, eventId: string = null, key = 'Cylinder'): EventCardItem {
  const focus = eventId ? `?focus=${eventId}` : ''

  return {
    props: { action: true, href: `/equipment/${eqStickerCode}${focus}`, target: '_blank' },
    key: key,
    child: <>{ eqStickerCode }</>,
  }
}

function EventCard({ hideCylinder = false, eventId, items }: EventCardProps & { eventId: string }) {
  const { t } = useTranslation()

  const toDisplay = compact(items)
    .filter(item => !hideCylinder || item.key !== 'Cylinder')

  const listItems = toDisplay.map((item, idx) => (
    <ListGroup.Item {...item.props ?? {} } key={idx} active={ false }>
      { item.key ? (<strong>{ t(item.key) }: </strong>) : null }
      { item.child }
    </ListGroup.Item>
  ))

  return (
    <ListGroup id={ `card-${eventId}` }>
      { listItems }
    </ListGroup>
  )
}

type CommonProps = Omit<EventCardProps, 'items'>

function Recovery({ event, ...props }: CommonProps & { event: GasRecovery }) {
  let recovered: string | null = null
  if (event.weightBefore && event.weightAfter) {
    recovered = (event.weightAfter - event.weightBefore).toFixed(2)
  }

  const items: EventCardProps['items'] = [
    event.eqStickerCode ? equipmentLink(event.eqStickerCode, event.id) : null,
    { key: 'Time', child: <DateStr date={ event.recoveryAt } /> },
    { key: 'Reason', child: <>{ event.recoveryReason }</> },
    { key: 'Person', child: <>{ event.technicianName }</> },
    { key: 'Location', child: <>{ event.locationName }</> },
    recovered ? { key: 'Refrigerant Weight', child: <>{ recovered } </> } : null,
    { key: 'Gas', child: <>{ event.reportedGas }</> },
    event.spreadsheetRowId ? spreadsheetIdLink(event, 'db_recovery') : null,
    {
      child: <EventImage id={ event.nameplatePhoto } label="Nameplate Photo" />,
    },
    {
      child: <EventImage id={ event.systemPhoto } label="System Photo" />,
    },
    {
      child: <EventImage id={ event.weightBeforePhoto } label="Weight Before" />,
    },
    {
      child: <EventImage id={ event.weightAfterPhoto } label="Weight After" />,
    },
    {
      child: <EventImage id={ event.cylinderCodePhoto} label="Sticker Photo" />,
    },
  ]

  const cardProps = {...props, items, eventId: event.id}
  return <EventCard {...cardProps} />
}

function QC({ event, ...props }: CommonProps & { event: EquipmentQc }) {
  const items: EventCardProps['items'] = [
    equipmentLink(event.eqStickerCode, event.id),
    { key: 'Status', child: <>{ event.status }</> },
    { key: 'User', child: <>{ event.userName }</> },
    { key: 'Notes', child: <>{ event.notes }</> },
    spreadsheetIdLink(event, 'db_qc'),
    {
      child: <EventImage id={ event.qcPhoto } label="QC Photo" />,
    },
  ]

  const cardProps = {...props, items, eventId: event.id}
  return <EventCard {...cardProps} />
}

function Vacuum({ event, ...props }: CommonProps & { event: EquipmentVacuum }) {
  const items: EventCardProps['items'] = [
    equipmentLink(event.eqStickerCode, event.id),
    { key: 'Pressure', child: <>{ event.pressure }</> },
    { key: 'Condition', child: <>{ event.condition }</> },
    { key: 'User', child: <>{ event.userName }</> },
    { key: 'Notes', child: <>{ event.notes }</> },
    spreadsheetIdLink(event, 'db_vakum'),
    {
      child: <EventImage id={event.vacuumPhoto} label="Vacuum Photo" />,
    },
  ]

  const cardProps = {...props, items, eventId: event.id}
  return <EventCard {...cardProps} />
}

function Move({ event, ...props }: CommonProps & { event: EquipmentMove }) {
  const items: EventCardProps['items'] = [
    equipmentLink(event.eqStickerCode, event.id),
    { key: 'Origin', child: <>{ event.originName }</> },
    { key: 'Origin PIC', child: <>{ event.originPerson }</> },
    { key: 'Destination', child: <>{ event.destName }</> },
    { key: 'Destination PIC', child: <>{ event.destPerson }</> },
    { key: 'User', child: <>{ event.userName }</> },
    spreadsheetIdLink(event, 'db_move'),
    {
      child: <EventImage id={event.equipmentPhoto}  label="Equipment Photo" />,
    },
    {
      child: <EventImage id={event.transportPhoto} label="Transport Photo" />,
    },
    {
      child: <EventImage id={event.handoverPhoto} label="Handover Photo" />,
    },

  ]

  const cardProps = {...props, items, eventId: event.id}
  return <EventCard {...cardProps} />
}

function Shipment({event, ...props }: CommonProps & { event: Shipment }){
  const moves = event.moves || []
  const eqLinks = moves.map((m) => equipmentLink(m.eqStickerCode, m.id))
  const items: EventCardProps['items'] = eqLinks.concat([
    { key: 'Origin', child: <>{ event.originName }</> },
    { key: 'Origin PIC', child: <>{ event.originPerson }</> },
    { key: 'Destination', child: <>{ event.destName }</> },
    { key: 'Destination PIC', child: <>{ event.destPerson }</> },
    { key: 'User', child: <>{ event.userName }</> },
    {
      child: <EventImage id={event.equipmentPhoto}  label="Equipment Photo" />,
    },
    {
      child: <EventImage id={event.transportPhoto} label="Transport Photo" />,
    },
    {
      child: <EventImage id={event.handoverPhoto} label="Handover Photo" />,
    },

  ])

  const cardProps = {...props, items, eventId: event.id}
  return <EventCard {...cardProps} />
}

function Weight({ event, ...props }: CommonProps & { event: EquipmentWeight }) {
  const items: EventCardProps['items'] = [
    equipmentLink(event.eqStickerCode, event.id),
    { key: 'Weight (kg)', child: <>{ event.weight }</> },
    {
      key: 'Scale',
      props: { action: true, to: `/equipment/${event.scaleId}`, as: Link },
      child: <>{ event.scaleStickerCode }</>,
    },
    { key: 'User', child: <>{ event.userName }</> },
    spreadsheetIdLink(event, 'db_weight'),
    {
      child: <EventImage id={event.weightPhoto} label="Weight Photo" />,
    },
  ]

  const cardProps = {...props, items, eventId: event.id}
  return <EventCard {...cardProps} />
}

function Borrow({ event, ...props }: CommonProps & { event: EquipmentBorrow }) {
  const items: EventCardProps['items'] = [
    equipmentLink(event.eqStickerCode, event.id),
    { key: 'Borrower name', child: <>{ event.borrowerName }</> },
    { key: 'Returned at', child: <>{ event.returnAt }</>},
    { key: 'Returned by', child: <>{ event.returnerName }</>},
  ]

  const cardProps = {...props, items, eventId: event.id}
  return <EventCard {...cardProps} />
}

function Transfer({ event, direction = 'Out',  ...props }: CommonProps & { event: GasTransfer, direction: 'In' | 'Out' }) {
  const my = direction === 'Out' ? 'origin' : 'dest'
  const other = direction === 'Out' ? 'dest' : 'origin'

  //const _myId = event[`${my}EquipmentId`]
  const myCode = event[`${my}StickerCode`]
  //const _otherId = event[`${other}EquipmentId`]
  const otherCode = event[`${other}StickerCode`]

  const [ originB, originA, destB, destA ] = [
    Number(event.originWeightBefore),
    Number(event.originWeightAfter),
    Number(event.destWeightBefore),
    Number(event.destWeightAfter),
  ]

  const weightOut = (originB && originA) ? originB - originA : null
  const weightIn = (destB && destA ) ? destA - destB : null

  const items: EventCardProps['items'] = [
    equipmentLink(myCode, event.id),
    { key: 'Weight Out', child: <>{ weightOut ? weightOut.toFixed(2) : 'N/A' }</> },
    { key: 'Weight In', child: <>{ weightIn ? weightIn.toFixed(2) : 'N/A' }</> },
    { key: 'Person', child: <>{ event.technicianName }</> },
    spreadsheetIdLink(event, 'db_transfer'),
    {
      ...equipmentLink(otherCode, event.id),
      key: `${other === 'dest' ? 'Destination' : 'Origin'} Cylinder`,
    },
    {
      child: <EventImage id={ event[`${my}BeforePhoto`]} label="Photo Before" />,
    },
    {
      child: <EventImage id={ event[`${my}AfterPhoto`] } label="Photo After" />,
    },
  ]

  const cardProps = {...props, items, eventId: event.id}
  return <EventCard {...cardProps} />
}

function DirectionAgnosticTransfer({ event, ...props }: CommonProps & { event: GasTransfer }) {

  const originCode = event.originStickerCode || 'none'
  const destCode = event.destStickerCode || 'none'

  const [ originB, originA, destB, destA ] = [
    Number(event.originWeightBefore),
    Number(event.originWeightAfter),
    Number(event.destWeightBefore),
    Number(event.destWeightAfter),
  ]

  const weightOut = (originB && originA) ? originB - originA : null
  const weightIn = (destB && destA ) ? destA - destB : null

  const items: EventCardProps['items'] = [
    equipmentLink(originCode, event.id, 'Origin Cylinder'),
    { key: 'Transfer Type', child: <>{ event.transferType }</> },
    { key: 'Origin Weight Before', child: <>{ originB ? originB.toFixed(2) : 'N/A' }</> },
    { key: 'Origin Weight After', child: <>{ originA ? originA.toFixed(2) : 'N/A' }</> },
    { key: 'Weight Out', child: <>{ weightOut ? weightOut.toFixed(2) : 'N/A' }</> },
    equipmentLink(destCode, event.id, 'Destination Cylinder'),
    { key: 'Destination Weight Before', child: <>{ destB ? destB.toFixed(2) : 'N/A' }</> },
    { key: 'Destination Weight After', child: <>{ destA ? destA.toFixed(2) : 'N/A' }</> },
    { key: 'Weight In', child: <>{ weightIn ? weightIn.toFixed(2) : 'N/A' }</> },
    { key: 'Person', child: <>{ event.technicianName }</> },
    spreadsheetIdLink(event, 'db_transfer'),
    {
      child: <EventImage id={ event.originBeforePhoto } label="Source Photo Before" />,
    },
    {
      child: <EventImage id={ event.originAfterPhoto } label="Source Photo After" />,
    },
    {
      child: <EventImage id={ event.destBeforePhoto } label="Destination Photo Before" />,
    },
    {
      child: <EventImage id={ event.destAfterPhoto } label="Destination Photo After" />,
    },
  ]

  const cardProps = {...props, items, eventId: event.id}
  return <EventCard {...cardProps} />
}

function Destruction({ event, ...props }: CommonProps & { event: GasDestruction }) {

  const items: EventCardProps['items'] = [
    { key: 'Reason', child: <>{ event.eventType }</> },
    equipmentLink(event.eqStickerCode, event.id),
    spreadsheetIdLink(event, 'db_destruction'),
    { key: 'User', child: <>{ event.userName }</> },
    { child: <EventImage id={event.eventDocument} label='Documentation' /> },
  ]

  const cardProps = {...props, items, eventId: event.id}
  return <EventCard {...cardProps} />
}

function Identification({ event, ...props }: CommonProps & { event: GasIdent }) {
  const { t } = useTranslation()

  const pctTable = <Table size='sm' borderless={ true }>
    <caption>{ t('Gas Percentages') }</caption>
    <thead><tr>
      <th>R134</th>
      <th>R12</th>
      <th>R22</th>
      <th>Hc</th>
      <th>Air</th>
    </tr></thead>
    <tbody><tr>
      <td>{ event.pctR134 }</td>
      <td>{ event.pctR12 }</td>
      <td>{ event.pctR22 }</td>
      <td>{ event.pctHc }</td>
      <td>{ event.pctAir }</td>
    </tr></tbody>
  </Table>

  const items: EventCardProps['items'] = [
    equipmentLink(event.eqStickerCode, event.id),
    { child: pctTable },
    { key: 'User', child: <>{ event.userName }</> },
    { key: 'Notes', child: <>{ event.notes }</> },
    spreadsheetIdLink(event, 'db_identifier'),
    {
      child: <EventImage id={event.idPhoto} label="Identification Photo" />,
    },
  ]

  const cardProps = {...props, items, eventId: event.id}
  return <EventCard {...cardProps} />
}


export {
  EventCard,
  equipmentLink,
  EventImage,
  Recovery,
  QC,
  Vacuum,
  Move,
  Shipment,
  Weight,
  Borrow,
  Transfer,
  DirectionAgnosticTransfer,
  Destruction,
  Identification,
}
export type { EventCardProps }
