import { useEffect, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Card from 'react-bootstrap/Card'

import { Link as LinkIcon } from 'react-bootstrap-icons';
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import Stack from 'react-bootstrap/Stack'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table'

import { useSearchString } from '../lib/searchState'
import { useEquipmentHistory, UseEquipmentHistoryT, useUpdateEvent } from 'client/src/queries';

import {
  Recovery, QC, Vacuum, Move, Weight, Borrow, Transfer, Destruction, Identification,
} from './EventCards'

import EquipmentDetails from './EquipmentDetails';
import EquipmentActions from './EquipmentActions';

import DateStr from './DateStr'

type Equipment = UseEquipmentHistoryT['equipment']
type History = UseEquipmentHistoryT['history']['equipmentHistory'][number]
type EquipmentStates = UseEquipmentHistoryT['history']['equipmentStates'][number]

type EventCardProps = {
  eventInfo: History,
  equipmentId: string,
  inFocus: boolean,
  onFocus: () => void,
}

function EventCard({ eventInfo, equipmentId, inFocus, onFocus }: EventCardProps ) {
  const { t } = useTranslation()

  useEffect(() => {
    if (inFocus) {
      const card = document.getElementById(eventInfo.event.id)
      card.scrollIntoView()
    }
  }, [eventInfo, inFocus])

  const type = eventInfo.type
  let title = type.charAt(0).toUpperCase() + type.slice(1)
  let text: ReactNode

  if (eventInfo.type === 'created') {
    const fields = eventInfo.event
    title = 'Equipment Created'
    text = <ul>
      <li><strong>{ t('Owner') }:</strong> { fields.owner }</li>
      <li><strong>{ t('User') }:</strong> { fields.userName }</li>
      <li><strong>{ t('Initial Location') }:</strong> { fields.initialLocation }</li>
    </ul>

  } else if (eventInfo.type === 'qc') {
    const fields = eventInfo.event
    title = 'QC'
    text = <QC hideCylinder={true} event={ fields } />
  } else if (eventInfo.type === 'vacuum') {
    const fields = eventInfo.event
    text = <Vacuum hideCylinder={true} event={ fields } />
  } else if (eventInfo.type === 'move') {
    const fields = eventInfo.event
    text = <Move hideCylinder={true} event={ fields } />
  } else if (eventInfo.type === 'weight') {
    const fields = eventInfo.event
    text = <Weight hideCylinder={true} event={ fields } />
  } else if (eventInfo.type === 'borrow') {
    const fields = eventInfo.event
    title = 'Check-out'
    text = <Borrow hideCylinder={true} event={ fields } />
  } else if (eventInfo.type === 'return') {
    const fields = eventInfo.event
    title = 'Check-in'
    text = <Borrow hideCylinder={true} event={ fields } />
  } else if (eventInfo.type === 'transfer') {
    const fields = eventInfo.event
    const direction = fields.originEquipmentId === equipmentId ? 'Out' : 'In'

    title = `Transfer ${direction} (${fields.transferType})`
    text = <Transfer hideCylinder={ true } event={ fields } direction={ direction } />
  } else if (eventInfo.type === 'recovery') {
    const fields = eventInfo.event

    title = 'Recovery'
    text = <Recovery hideCylinder={ true } event={ fields } />
  } else if (eventInfo.type === 'destruction') {
    const fields = eventInfo.event

    title = `Destruction (${fields.eventType})`
    text = <Destruction hideCylinder={ true } event={ fields } />
  } else if (eventInfo.type === 'ident') {
    const fields = eventInfo.event
    title = 'Gas Identification'

    text = <Identification hideCylinder={ true } event={ fields } />
  } else if (eventInfo.type === 'tareWeight') {
    title = 'Tare Weight'

    text = <span>Tare weight not supported</span>
  } else {
    const _fields = eventInfo?.event  // eslint-disable-line
  }

  const style = inFocus ? { border: '1px solid blue' } : {}

  return (
    <Card id={ eventInfo.event.id } style={ style }>
      <Card.Body>
        <Card.Title>
          <Stack direction="horizontal" gap={1}>
            <DateStr date={ eventInfo.ts } className="text-secondary" />
            <span className="text-primary me-auto">{ t(title) }</span>

            <Button
              variant={ inFocus ? 'primary' : 'outline-primary' }
              active={ inFocus }
              disabled={ inFocus }
              size='sm'
              className="rounded"
              onClick={ onFocus }
            >
              <LinkIcon />
            </Button>
          </Stack>
        </Card.Title>
        <Card.Text as="div">{ text }</Card.Text>
      </Card.Body>
    </Card>
  )
}

type HistoryTableP = {
  equipment: UseEquipmentHistoryT['equipment'],
  history: UseEquipmentHistoryT['history'],
}

function HistoryTable({ equipment, history }: HistoryTableP) {
  const { t } = useTranslation()
  const [ focus, setFocus ] = useSearchString('', 'focus')

  const recentFirst = history.equipmentHistory.slice().reverse()

  return (
    <div>
      <h1>{ t('Equipment History') }</h1>
      {
        recentFirst.map(eventInfo => (
          <EventCard
            eventInfo={eventInfo}
            equipmentId={equipment.id}
            inFocus={focus === eventInfo.event.id}
            onFocus={() => setFocus(eventInfo.event.id)}
            key={`${eventInfo.type}-${eventInfo.event.id}`}
          />
        ))
      }
    </div>
  )
}

type StateTableP = {
  equipment: UseEquipmentHistoryT['equipment'],
  states: UseEquipmentHistoryT['history']['equipmentStates'],
}

function StateTable({ equipment, states }: StateTableP) {
  const { t } = useTranslation()
  const [ focus, setFocus ] = useSearchString('', 'focus')
  const [ showIds, setShowIds ] = useState(false)

  return (
    <div>
      <h1>{ t('Equipment State Table') }</h1>
      <Table size="sm" striped={true}>
        <StateTableHead equipmentType={equipment.equipmentType} showIds={showIds} setShowIds={setShowIds} />
        <tbody>{states.map(eventInfo => (
          <StateRow
            key={`${eventInfo.eventType}-${eventInfo.eventId}`}
            eventInfo={eventInfo}
            inFocus={focus === eventInfo.eventId}
            onFocus={() => setFocus(eventInfo.eventId)}
            showIds={showIds}
          />))}
        </tbody>
      </Table>
    </div>
  )
}

function StateTableHead({equipmentType, showIds, setShowIds}: {equipmentType: Equipment['equipmentType'], showIds: boolean, setShowIds: (newVal: boolean) => void}) {
  const { t } = useTranslation()
  let cols = [
    <th>{t('Link')}</th>,
    <th>
    ID{' '}
    <button
      onClick={() => setShowIds(!showIds)}
      style={{
        background: 'none',
        border: 'none',
        padding: 0,
        color: 'blue',
        cursor: 'pointer',
        textDecoration: showIds ? 'line-through underline' : 'underline',
      }}
    >
      show
    </button>
    </th>,
    <th>{t('Time')}</th>,
    <th>{t('Type')}</th>,
    <th>{t('Spreadsheet ID')}</th>,
    <th>{t('Current Location')}</th>,
    <th>{t('Condition')}</th>,
    <th>{t('Steward')}</th>,
  ]
  if (equipmentType === 'cylinder') {
    cols = cols.concat([
      <th>{t('Gas Type')}</th>,
      <th>{t('Gas Delta')}</th>,
      <th>{t('Net Weight')}</th>,
      <th>{t('Gross Weight')}</th>,
      <th>{t('Is Fresh?')}</th>,
    ]);
  }
  return <thead><tr>{cols}</tr></thead>
}

function maybeToFixed(x: number | null): string {
  return (x === null) ? '' : x.toFixed(2)
}

type StateRowProps = {
  eventInfo: EquipmentStates,
  inFocus: boolean,
  onFocus: () => void,
  showIds: boolean,
}
function StateRow({ eventInfo, inFocus, onFocus, showIds }: StateRowProps ) {
  const id = eventInfo.eventId
  useEffect(() => {
    if (inFocus) {
      const row = document.getElementById(eventInfo.eventId)
      row.scrollIntoView()
    }
  }, [eventInfo, inFocus])

  const style = inFocus ? { border: '1px solid blue' } : {}
  let vals = [
       (<td>
            <Button
              variant={ inFocus ? 'primary' : 'outline-primary' }
              active={ inFocus }
              disabled={ inFocus }
              size='sm'
              className="rounded"
              onClick={ onFocus }
            >
              <LinkIcon />
            </Button>
        </td>),
        <td>
          <Link to={ `/events/${eventInfo.eventId}` } target="_blank">
            {showIds ? id : `${id.slice(0, 6)}...${id.slice(-6)}`}
          </Link>
        </td>,
        <td><DateStr date={eventInfo.ts}/></td>,
        <td>{eventInfo.eventType}</td>,
        <td>{eventInfo.spreadsheetRowId}</td>,
        <td>{eventInfo.locationName}</td>,
        <td>{eventInfo.condition}</td>,
        <td>{eventInfo.steward}</td>,
  ]
  if (eventInfo.cylinderState) {
      vals = vals.concat([
          <td>{eventInfo.cylinderState.gasType}</td>,
          <td>{maybeToFixed(eventInfo.cylinderState.gasDelta)}</td>,
          <td>{eventInfo.cylinderState.gasQuantity.toFixed(2)}</td>,
          <td>{maybeToFixed(eventInfo.cylinderState.grossWeight)}</td>,
          <td>{eventInfo.cylinderState.isFreshlyVacuumed.toString()}</td>,
      ]);
  }
  // TODO(Louis): add link from spreadsheetRowId
  return (
    <tr
        id={eventInfo.eventId}
        style={ style }>
        {vals}
    </tr>
  )
}

type TareWeightTableP = {
  equipment: UseEquipmentHistoryT['equipment'],
  history: UseEquipmentHistoryT['history']['equipmentHistory'],
}
type HistoryEvent = UseEquipmentHistoryT['history']['equipmentHistory'][number]
function eventToTareWeight ({equipmentId, event}: {equipmentId: string, event: HistoryEvent}) {
  let tareWeight: {weightAt: Date, weightKg: number}
  if (event.type === 'recovery') {
    tareWeight = {weightKg: event.event.weightBefore, weightAt: event.event.recoveryAt}
  }
  if (event.type === 'weight') {
    tareWeight = {weightKg: event.event.weight, weightAt: event.event.weightAt}
  }
  if (event.type === 'transfer') {
    const weightKg = (equipmentId === event.event.originEquipmentId) ? event.event.originWeightAfter : event.event.destWeightBefore
    tareWeight = {weightKg, weightAt: event.event.transferAt}
  }
  return tareWeight
}

function TareWeightRow({ equipment, eventInfo, alreadyUsed }: { equipment: UseEquipmentHistoryT['equipment'], eventInfo: HistoryEvent, alreadyUsed: boolean }) {
  const mutate = useUpdateEvent()
  const { t } = useTranslation()
  const tareWeight = eventToTareWeight({equipmentId: equipment.id, event: eventInfo})
  return (
    <tr key={eventInfo.event.id}>
      <td><DateStr date={eventInfo.ts} /></td>
      <td>{eventInfo.type}</td>
      <td>{tareWeight.weightKg}</td>
      <td>
        { alreadyUsed ?
          (<span>{t('already used')}</span>) : (
        <Button
          variant='outline-primary'
          size='sm'
          onClick={() => mutate.mutate({
          type: 'tareWeight',
          event: {
            weightAt: tareWeight.weightAt,
            weightKg: tareWeight.weightKg,
            eventId: eventInfo.event.id,
            eventType: eventInfo.type,
            equipmentId: equipment.id,
          }})}>
          {t('Set Tare Weight')}
        </Button>
          )}
      </td>
    </tr>
  )
}

function TareWeight({ equipment, history }: TareWeightTableP) {
  return (
    <>
      <NewTareWeight equipment={equipment}/>
    <TareWeightTable equipment={equipment} history={history} />
    </>
  )
}

const CreateTareWeightVld = z.object({
  weightKg: z.number(),
  notes: z.string().min(10, 'please provide justification (> 10 chars required)'),
})
type CreateTareWeightT = z.infer<typeof CreateTareWeightVld>
function NewTareWeight({equipment}: {equipment: UseEquipmentHistoryT['equipment']}) {
  const { t } = useTranslation()

  const { mutate, isSuccess } = useUpdateEvent()

  const {
    register, handleSubmit, formState: { errors },
  } = useForm<CreateTareWeightT>({
    resolver: zodResolver(CreateTareWeightVld),
  })

  if (isSuccess) {
    return <><div className='card card-success'><div className="card-body">
      Thanks!
    </div></div></>
  }
  const onSubmit = handleSubmit((data: CreateTareWeightT) => {
    mutate(
      {type: 'tareWeight',
       event: {
         weightKg: data.weightKg,
         notes: data.notes,
         equipmentId: equipment.id,
       }}
    )})
  return (<>

    <Row><Col md="8" lg="3">
      <h4>Enter new tare weight</h4>
    <Form onSubmit={onSubmit}>
        <Form.Group controlId="weightKg" className="mb-3">
          <Form.Label>
            { t('Tare Weight (kg)') }
          </Form.Label>
          <Form.Control
            type="number"
            step="0.01"
            placeholder={ t('Enter Tare Weight in kg...') }
            {...register('weightKg', {valueAsNumber: true})}
            isInvalid={ 'weightKg' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.weightKg?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="notes" className="mb-3">
          <Form.Label>
            { t('Notes') }
          </Form.Label>
          <Form.Control
            as="textarea"
            placeholder={ t('Enter explanation....') }
            {...register('notes')}
            isInvalid={ 'name' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.notes?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>
        <Button variant="primary" type="submit">
          { t('Submit') }
        </Button>
    </Form>
    </Col></Row>
  </>)

}

function TareWeightTable({ equipment, history }: TareWeightTableP) {
  const { t } = useTranslation()
  const eligibleTypes = ['weight', 'transfer', 'recovery']
  const events = history.filter(eventInfo => eligibleTypes.includes(eventInfo.type))
  const tareWeightEvents =  (
    history
    .filter(eventInfo => eventInfo.type === 'tareWeight')
    // @ts-expect-error we know it's a tare weight
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .map(eventInfo => eventInfo.event.eventId)
  )


  return (
    <Table>
      <thead><tr>
        <th>{t('Time')}</th>
        <th>{t('Type')}</th>
        <th>{t('Weight')}</th>
        <th>{t('Action')}</th>
      </tr></thead>
      <tbody>
        {events.map(eventInfo => (
          <TareWeightRow
            key={eventInfo.event.id}
            equipment={equipment}
            eventInfo={eventInfo}
            alreadyUsed={tareWeightEvents.includes(eventInfo.event.id)}
        />))}
      </tbody>

    </Table>
  )



}

function EquipmentPage() {
  const { t } = useTranslation()
  const { stickerCode } = useParams()

  const [ historyView, setHistoryView ] = useSearchString('state', 'view')
  const { isLoading, error, data } = useEquipmentHistory(stickerCode)

  if (isLoading) {
    return (<Stack direction='horizontal' gap={1}>
      <Spinner variant='primary' animation='border' />
      <h3>{ t('Loading equipment info...') }</h3>
    </Stack>)
  }

  if (error) {
    return (<Stack direction='vertical' gap={1}>
      <h3>{ t('Loading equipment info failed!') }</h3>
      <pre className="p-2">
        { (error as Error).message }
      </pre>
    </Stack>)
  }

  const equipment = data.equipment
  const equipmentState = data.history.equipmentStates.at(-1)

  return (
    <Stack gap={2}>
      <Row>
        <Col xs={12} md={8} lg={7}>
          <EquipmentDetails equipment={equipment} equipmentState={equipmentState} />
        </Col>

        <Col xs={12} md={4} lg={5}>
          <EquipmentActions equipment={equipment} isBorrowed={!!equipmentState.borrowAt} />
        </Col>
      </Row>

      <Tabs
        activeKey={ historyView }
        onSelect={(tab) => setHistoryView(tab)}
        className="pt-2 border-top"
      >
        <Tab eventKey="state" title={ t('Historic State') }>
          <StateTable equipment={data.equipment} states={data.history.equipmentStates} />
        </Tab>

        <Tab eventKey="card" title={ t('Event Cards') }>
          <HistoryTable equipment={data.equipment} history={data.history} />
        </Tab>

        {
          (equipment.equipmentType === 'cylinder') ?
          (<Tab eventKey="tareWeight" title={ t('Tare Weight') }>
            <TareWeight equipment={data.equipment} history={data.history.equipmentHistory} />
          </Tab>)
         : <></>
        }
      </Tabs>
    </Stack>
  );
}

export default EquipmentPage;
