import { useState } from 'react'
import { useQuery, useMutation } from 'react-query'

import dayjs from 'dayjs'
import { compact } from 'lodash-es'

import queryClient from './client'
import { makeApiRequest, dataOrThrow } from './utils'

import type {
  EventsSearchResp,
  EventsPostResp,
  PostImageResp,
  GetImageResp,
  EventDetailResp,
  ShipmentDetailResp,
} from '../../api-rec/src/controller/events'

import type {
  GetEquipmentResp,
  GetStickersResp,
  GetEquipmentSeriesResp,
  GetEquipmentStatesResp,
  GetEquipmentByStickerResp,
  GetEquipmentLocationsResp,
  GetEquipmentBorrowedResp,
  GetEquipmentHistoryResp,
  GetEquipmentStateResp,
  UpdateLocationReq,
  UpdateLocationResp,
} from '../../api-rec/src/controller/equipment'
import type { GetTodoResp } from '../../api-rec/src/controller/ops'
import type { GraphFilter, GasSearchResp, GasPurchaseGraphResp } from '../../api-rec/src/controller/gas'
import type {
  TokenRequest,
  TokenValidate,
  GetAuthSessionResp,
  TechnicianPostResp,
} from '../../api-rec/src/controller/auth'
import type { ErrorLogGetResp } from '../../api-rec/src/controller/errorLog'
import type { GetPaymentsResp } from '../../api-rec/src/controller/payments'
import type { UserPostRequest, UserAvatarResp, UserDetailResp } from '../../api-rec/src/controller/user'
import type {
  EmailSignupBody,
  EnterpriseSignupBody,
  GetRegistryResp,
  GetRegistryUserSubscriptions,
  RegistryPurchaseBody,
  RegistryPurchaseResp,
  GetPurchaseResp,
} from '../../api-rec/src/controller/registry'


import type { LeakLocation, LeakLocationDetail, LeakLocationCategory  } from '../../api-rec/src/db/serviceCalls'

type UseEventsT = EventsSearchResp
function useEvents(eqIds: string[], minDate: string, maxDate: string, types: string[], includeDeleted = false) {
  return useQuery(
    ['search', 'events', eqIds, minDate, maxDate, types, includeDeleted],
    () => dataOrThrow<UseEventsT>(
      '/api/events',
      {
        method: 'PUT',  // at least it's idempotent!
        headers: {
          'Content-Type': 'application/json',
          'X-HTTP-Method-Override': 'SEARCH',
        },
        body: JSON.stringify({
          equipment: eqIds,
          minDate: minDate,
          maxDate: maxDate,
          types: types,
          includeDeleted: includeDeleted,
        }),
      }
    )
  )
}

function useEventsForUser(eventType: string) {
  return useQuery(
    ['get', 'events', 'forUser', eventType],
    () => dataOrThrow<UseEventsT>(
      `/api/events/forUser?eventType=${eventType}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
    )
  )
}

function useEventForUser({eventType, eventId}: {eventType:string, eventId: string}) {
  return useQuery(
    ['get', 'events', 'forUser', eventId],
    () => dataOrThrow<UseEventDetailT>(
      `/api/events/forUser/${eventId}?eventType=${eventType}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
    )
  )
}

type UseOpsTodoT = GetTodoResp
function useOpsTodo() {
  return useQuery(
    ['get', 'ops', 'todo'],
    () => dataOrThrow<UseOpsTodoT>('/api/ops/todo')
  )
}

type UseErrorLogT = ErrorLogGetResp
function useErrorLog() {
  return useQuery(
    ['get', 'errorLog'],
    () => dataOrThrow<UseErrorLogT>('/api/errorLog')
  )
}

type RequestTokenParams = TokenRequest

function useRequestToken() {
  const [ requested, setRequested ] = useState(false)

  const requestToken = useMutation(
    (req: RequestTokenParams) => dataOrThrow(
      '/api/auth/token/request',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req),
      },
    ),
    {
      onSuccess: () => { setRequested(true) },
    }
  )

  const reset = () => {
    requestToken.reset()
    setRequested(false)
  }

  return { requested, requestToken, reset }
}

type ValidateTokenParams = TokenValidate
function useValidateToken() {
  return useMutation(
    (req: ValidateTokenParams) => dataOrThrow(
      '/api/auth/token/validate',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req),
      },
    ),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(['get', 'auth', 'session'])
      },
    },
  )
}

function useUpdateUser() {
  return useMutation(
    (user: UserPostRequest) => dataOrThrow(
      '/api/user',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user),
      },
    ),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(['get', 'auth', 'session'])
      },
    },
  )
}

type UseUserT = UserDetailResp
function useUser(userId: string) {
  return useQuery(
    ['get', 'user', userId],
    () => dataOrThrow<UseUserT>(`/api/user/${userId}`),
    {
      enabled: !!userId,
      refetchOnWindowFocus: false,
    },
  )
}

type UseUserAvatarT = UserAvatarResp
function useUserAvatar(userId: string) {
  return useQuery(
    ['get', 'user', 'avatar', userId],
    () => dataOrThrow<UseUserAvatarT>(`/api/user/avatar/${userId}`),
    )
}

function useLogout() {
  return useMutation(
    () => dataOrThrow( '/api/auth/logout', { method: 'POST' }),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(['get', 'auth', 'session'])
      },
    },
  )
}

type UseSessionT = GetAuthSessionResp
function useSession() {
  return useQuery(
    ['get', 'auth', 'session'],
    () => dataOrThrow<UseSessionT>('/api/auth/session')
  )
}

function useDeleteEvent() {
  return useMutation(
    ({ event, type }: UpdateEventParams) => dataOrThrow<UseUpdateEventT>(
      '/api/events',
      {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ event: {id: event.id}, type }),
      },
    ),
    {
      onSuccess: (data) => {
        void queryClient.invalidateQueries(['get', 'events', data.event.id])
      },
    },
  )
}

type UpdateEventParams = { event: Record<string, unknown>, type: string }
type UseUpdateEventT = EventsPostResp
function useUpdateEvent() {
  return useMutation(
    ({ event, type }: UpdateEventParams) => dataOrThrow<UseUpdateEventT>(
      '/api/events',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ event, type }),
      },
    ),
    {
      onSuccess: (data) => {
        // right now this is only used by OpsTasks, so this makes sense.
        // if we use this for other things, we'll need to change
        void queryClient.invalidateQueries(['get', 'ops', 'todo'])
        // need for Equipment component
        void queryClient.invalidateQueries(['get', 'equipment'])
        // for service call
        void queryClient.invalidateQueries(['get', 'events', data.event.id])
      },
    }
  )
}

type UpdateTechnicianParams = { event: Record<string, unknown>, type: string }
type UseUpdateTechnicianT = TechnicianPostResp
function useUpdateTechnician() {
  return useMutation(
    ({ event, type }: UpdateTechnicianParams) => dataOrThrow<UseUpdateTechnicianT>(
      '/api/auth/technician',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ event, type }),
      },
    ),
    {
      onSuccess: () => {
        // right now this is only used by OpsTasks, so this makes sense.
        // if we use this for other things, we'll need to change
        void queryClient.invalidateQueries(['get', 'ops', 'todo'])
      },
    }
  )
}

type UseEquipmentT = GetEquipmentResp
function useEquipment(types?: string[]) {
  const params = new URLSearchParams()
  if (types) {
    types.map(type => params.append('type', type))
  }

  const url = `/api/equipment${params.toString() ? `?${params.toString()}` : ''}`

  return useQuery(
    ['get', 'equipment', params.toString()],
    () => dataOrThrow<UseEquipmentT>(url),
    {
      refetchOnWindowFocus: false,
    },
  )
}

type UseStickersT = GetStickersResp
function useStickers(unusedOnly: boolean) {
  const params = new URLSearchParams()
  params.append('unusedOnly', unusedOnly ? '1' : '0')

  const url = `/api/equipment/stickers${params.toString() ? `?${params.toString()}` : ''}`

  return useQuery(
    ['get', 'equipment', 'stickers', params.toString()],
    () => dataOrThrow<UseStickersT>(url),
    {
      refetchOnWindowFocus: false,
    },
  )
}

type UseEquipmentSeriesT = GetEquipmentSeriesResp
function useEquipmentSeries() {

  const url = '/api/equipment/series'

  return useQuery(
    ['get', 'equipment', 'series'],
    () => dataOrThrow<UseEquipmentSeriesT>(url),
    {
      refetchOnWindowFocus: false,
    },
  )
}

type UsePurchaseT = GetPurchaseResp
function usePurchase(id: string) {
  return useQuery(
    ['get', 'purchase', id],
    () => dataOrThrow<UsePurchaseT>(`/api/registry/purchase/${id}`),
    {
      refetchOnWindowFocus: false,
    },
  )
}

// TODO: should we just make this two different hooks?
type UsePurchasesT = GetPaymentsResp
function usePurchases(forUser = false) {
  const url = forUser ? '/api/registry/user/purchases' : '/api/payments'
  const key = forUser ? ['get', 'payments'] : ['get', 'registry', 'user', 'payments']

  return useQuery(
    key,
    () => dataOrThrow<UsePurchasesT>(url),
    {
      refetchOnWindowFocus: false,
      select: (data) => {
        for (const p of data.purchases) {
          p.purchasedAt = new Date(p.purchasedAt)
        }

        return data
      },
    },
  )
}

type UseRegistryT = GetRegistryResp
function useRegistry() {
  const queryKey = ['get', 'registry']
  return useQuery(
    queryKey,
    () => dataOrThrow<UseRegistryT>(
      '/api/registry',
      {method: 'GET'}
    ),
    {select: (data) => {
      for (const p of data.purchases) {
        p.date = new Date(p.date)
      }
      return data
    }},
  )
}

type UseStripeSubscriptionsT = GetRegistryUserSubscriptions
function useStripeSubscriptions() {
  return useQuery(
    ['get', 'registry', 'user', 'subscriptions'],
    () => dataOrThrow<UseStripeSubscriptionsT>('/api/registry/user/subscriptions'),
    {
      refetchOnWindowFocus: false,
      select: (data) => {
        for (const item of data.subscriptions) {
          item.created = new Date(item.created)
          item.ended = item.ended ? new Date(item.ended) : item.ended
          item.nextBilling = item.nextBilling ? new Date(item.nextBilling) : item.nextBilling
        }
        return data
      },
    },
  )
}

export type UseEquipmentStatesT = GetEquipmentStatesResp
export function useEquipmentStates() {
  return useQuery(
    ['get', 'equipment', 'states'],
    () => dataOrThrow<UseEquipmentStatesT>('/api/equipment/states'),
    {
      refetchOnWindowFocus: false,
    },
  )
}

type UseEquipmentByStickerT = GetEquipmentByStickerResp
function useEquipmentBySticker(stickerUUID: string) {
  return useQuery(
    ['get', 'equipment', 'bySticker', stickerUUID],
    () => dataOrThrow<UseEquipmentByStickerT>(`/api/equipment/bySticker/${stickerUUID}`),
    {
      enabled: !!stickerUUID,
    }
    )
}

type UseEquipmentLocationsT = GetEquipmentLocationsResp
function useEquipmentLocations() {
  return useQuery(
    ['get', 'equipment', 'locations'],
    () => dataOrThrow<UseEquipmentLocationsT>('/api/equipment/locations'),
    {
      refetchOnWindowFocus: false,
    },
  )
}


type UseEquipmentBorrowedT = GetEquipmentBorrowedResp
function useEquipmentBorrowed() {
  return useQuery(
    ['get', 'equipment', 'borrowed'],
    () => dataOrThrow<UseEquipmentBorrowedT>('/api/equipment/borrowed'),
    {
      refetchOnWindowFocus: false,
    },
  )
}

type UseEquipmentHistoryT = GetEquipmentHistoryResp
function useEquipmentHistory(stickerCode: string) {
  return useQuery(
    ['get', 'equipment', 'history', stickerCode],
    () => dataOrThrow<UseEquipmentHistoryT>(`/api/equipment/history/${stickerCode}`),
  )
}

type UseEquipmentStateT = GetEquipmentStateResp
function useEquipmentState(id: string) {
  return useQuery(
    ['get', 'equipment', id, 'state'],
    () => dataOrThrow<UseEquipmentStateT>(`/api/equipment/${id}/state`),
    {
      enabled: !!id,
    },
  )
}

type UseEventDetailT = EventDetailResp
function useEventDetail(eventId: string) {
  return useQuery(
    ['get', 'events', eventId],
    () => dataOrThrow<UseEventDetailT>(`/api/events/${eventId}`),
    )
}

type UseShipmentDetailT = ShipmentDetailResp
function useShipmentDetail(eventId: string) {
  return useQuery(
    ['get', 'events', 'shipment', eventId],
    () => dataOrThrow<UseShipmentDetailT>(`/api/events/shipment/${eventId}`),
    )
}

type UseEventImageT = GetImageResp
function useEventImage(imageId: string | null) {
  return useQuery(
    ['get', 'events', 'image', imageId],
    () => imageId ? dataOrThrow<UseEventImageT>(`/api/events/image/${imageId}`) : null,
    {
      refetchOnWindowFocus: false,
      refetchInterval: 1000 * 60 * 60 * 24, // 1 day
      retryOnMount: false,
    },
  )
}

type ProgressFile = {
  field: string,
  file: File,
  imageId?: string,
  signedUrl?: string,
  registered: boolean,
  uploaded: boolean,
  error: string | null,
}

type UpdateWithPhotosProgress = {
  files: Array<ProgressFile>,
  running: boolean,
  done: boolean,
}

const initProgress: UpdateWithPhotosProgress = { files: [], running: false, done: false }

async function registerImage(file: ProgressFile, updateProgress: () => void, technicianRegistration: boolean = false) {
  const url = technicianRegistration ? '/api/events/image/technician' : '/api/events/image'
  const result = await makeApiRequest<PostImageResp>(
    url,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        contentType: file.file.type,
        originalFilename: file.file.name,
        lastModified: dayjs(file.file.lastModified),
      }),
    },
  )

  file.error = result.error
  if (result.success) {
    file.registered = true
    file.imageId = result.data.imageId
    file.signedUrl = result.data.signedUrl
  }

  updateProgress()
}

async function uploadImage(file: ProgressFile, updateProgress: () => void) {
  try {
    const result = await fetch(
      file.signedUrl!,
      {
        method: 'PUT',
        headers: { 'Content-Type': file.file.type },
        body: file.file,
      },
    )

    if (result.ok) {
      file.uploaded = true
    } else {
      const error = await result.text()
      file.error = `${result.statusText}: ${error}`
    }

  } catch(err) {
    file.error = `Network error: ${String(err)}`
  }

  updateProgress()
}

type UseUpdateLocationT = UpdateLocationResp
function useUpdateLocation() {
  return useMutation(
    (location: UpdateLocationReq) => dataOrThrow<UseUpdateLocationT>(
      '/api/equipment/location',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(location),
      },
    ),
    {
      onSuccess: (newLoc: UseUpdateLocationT) => {
        void queryClient.setQueryData<GetEquipmentLocationsResp>(
          ['get', 'equipment', 'locations'],
          (oldData) => {
          return {
            success: true,
            locations: [...oldData!.locations, newLoc.location],
        }})
      },
    },
  )
}

type SpecialEvents = 'technicianRegistration' | 'equipmentBorrow' | 'equipmentMove' | 'shipment'

function useUpdateEventWithPhotos({specialEvents, photoArray}: {specialEvents?: SpecialEvents, photoArray?: string} = {}) {
  const [progress, setProgress] = useState<UpdateWithPhotosProgress>(initProgress)

  const mutate = useMutation(
    async ({ event, type }: UpdateEventParams) => {
      // what files do we need to upload?
      const files: ProgressFile[] = []
      for (const [field, val] of Object.entries(event)) {
        if (val instanceof File) {
          files.push({field, file: val, registered: false, uploaded: false, error: null })
        }
        if (field === photoArray && Array.isArray(val)) {
          for (let i = 0; i < val.length; i++) {
            let photo: File
            if (val[i] instanceof File) {
              photo = val[i] as File
              files.push({field: `${photoArray}_${i}`, file: photo, registered: false, uploaded: false, error: null })
            }
          }
        }
      }

      const updateProgress = (updates: Partial<UpdateWithPhotosProgress> = {}) => {
        setProgress((progress) => ({
          ...progress,
          ...updates,
          files,
        }))
      }

      // initialize progress
      updateProgress({ running: true })

      // register all our files
      const regPromises = files.map(file => registerImage(
        file, updateProgress, (specialEvents === 'technicianRegistration')
      ))

      // make sure all registrations succeeded
      await Promise.all(regPromises)
      const regErrors = compact(files.map(f => f.error))
      if (regErrors.length) {
        throw new Error('not all files registered successfully')
      }

      // now upload all those files
      const uploadPromises = files.map(file => uploadImage(file, updateProgress))

      // make sure all uploads succeeded
      await Promise.all(uploadPromises)
      const uploadErrors = compact(files.map(f => f.error))
      if (uploadErrors.length) {
        throw new Error('not all files uploaded successfully')
      }

      // replace files with file ids in the event
      const ids = Object.fromEntries(files.map(file => [file.field, file.imageId]))
      event = {...event, ...ids}
      if (photoArray) {
        const arrayPhotos = []
        const n = (event[photoArray] as Array<File>).length
        for (let i = 0; i < n; i++) {
          arrayPhotos.push(ids[`${photoArray}_${i}`])
        }
        event[photoArray] = arrayPhotos
      }

      // return the results of creating the event itself
      let url
      if (specialEvents === 'technicianRegistration') {
        url = '/api/auth/technician'
      } else if (specialEvents === 'equipmentBorrow') {
        url = '/api/events/borrows'
      } else if (specialEvents === 'equipmentMove') {
        url = '/api/events/moves'
      } else if (specialEvents === 'shipment') {
        url = '/api/events/shipments'
      } else {
        url = '/api/events'
      }
      return dataOrThrow<UseUpdateEventT>(
        url,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ event, type }),
        },
      )
    },
    {
      onSettled: () => {
        setProgress((progress) => ({ ...progress, done: true}))
      },
      onSuccess: (data) => {
        void queryClient.invalidateQueries(['get', 'ops', 'todo'])

        // the response doesn't include user id
        // so we can't invalidate only the specific user.
        void queryClient.invalidateQueries(['get', 'user'])

        // since sometimes we are submitting this before the event is created
        if (data.event) {
          void queryClient.invalidateQueries(['get', 'events', 'forUser', data.event.id])
        }
      },
    }
  )

  const reset = () => {
    if (!progress.running || progress.done) {
      mutate.reset()
      setProgress(initProgress)
    }
  }

  return { progress, mutate, reset }
}

type UseGasT = GasSearchResp
function useGas(filter: GraphFilter, transform: (data: UseGasT) => UseGasT ) {
  const queryKey = ['search', 'gas', filter]

  const updateNodes = (nodes: UseGasT['nodes']) =>
    queryClient.setQueryData(queryKey, (data: UseGasT | undefined) => {
      return data ? {...data, nodes} : { nodes, success: true as const, edges: [] }
    })

  const useQueryRet = useQuery(
    queryKey,
    () => dataOrThrow<UseGasT>(
      '/api/gas/transferChains',
      {
        method: 'PUT',  // at least it's idempotent!
        headers: {
          'Content-Type': 'application/json',
          'X-HTTP-Method-Override': 'SEARCH',
        },
        body: JSON.stringify(filter),
      },
    ),
    {
      refetchOnWindowFocus: false,
      retryOnMount: false,
      refetchInterval: 1000 * 60 * 60, // 1 hour
      select: transform,
    },
  )

  return {...useQueryRet, updateNodes}
}

type UsePurchaseGraphT = GasPurchaseGraphResp
function usePurchaseGraph(purchaseId: string) {
  return useQuery(
    ['get', 'events', purchaseId],
    () => dataOrThrow<UsePurchaseGraphT>(`/api/gas/purchase/${purchaseId}`),
    {
      select: (data) => {
        for (const col of Object.values(data.events)) {
          for (const evt of col) {
            evt.ts = new Date(evt.ts!)
          }
        }

        for (const path of data.paths) {
          for (const edge of path.edges) {
            edge.date = new Date(edge.date)
          }
        }

        return data
      },
    },
  )
}

type UseEmailSignupFormT = EmailSignupBody
function useEmailSignupForm() {
  return useMutation(
    (signup: UseEmailSignupFormT) => dataOrThrow(
      '/api/registry/emailSignup',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(signup),
      },
    ),
  )
}

type UseEnterpriseSignupFormT = EnterpriseSignupBody
function useEnterpriseSignupForm() {
  return useMutation(
    (signup: UseEnterpriseSignupFormT) => dataOrThrow(
      '/api/registry/signup/enterprise',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(signup),
      },
    ),
  )
}

type UseRegistryPurchaseT = RegistryPurchaseBody
function useRegistryPurchase() {
  return useMutation(
    (purchase: UseRegistryPurchaseT) => dataOrThrow<RegistryPurchaseResp>(
      '/api/registry/purchase',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(purchase),
      },
    ),
  )
}

export {
  useEvents,
  useEventsForUser,
  useEventForUser,
  useOpsTodo,
  useErrorLog,
  useUpdateEvent,
  useDeleteEvent,
  useEquipment,
  useEquipmentSeries,
  usePurchase,
  usePurchases,
  useRegistry,
  useStripeSubscriptions,
  useStickers,
  useEquipmentBySticker,
  useEquipmentLocations,
  useUpdateLocation,
  useEquipmentBorrowed,
  useEquipmentHistory,
  useEquipmentState,
  useEventImage,
  useEventDetail,
  useShipmentDetail,
  useUpdateEventWithPhotos,
  useGas,
  useRequestToken,
  useValidateToken,
  useLogout,
  useSession,
  useUpdateUser,
  useUser,
  useUpdateTechnician,
  usePurchaseGraph,
  useEmailSignupForm,
  useEnterpriseSignupForm,
  useRegistryPurchase,
  useUserAvatar,
}

export type {
  UseEventsT,
  UseEventDetailT,
  UseOpsTodoT,
  UseErrorLogT,
  UseUpdateEventT,
  UseEquipmentT,
  UsePurchaseT,
  UsePurchasesT,
  UseRegistryT,
  UseStripeSubscriptionsT,
  UseStickersT,
  UseEquipmentByStickerT,
  UseEquipmentLocationsT,
  UseUpdateLocationT,
  UseEquipmentBorrowedT,
  UseEquipmentHistoryT,
  UseEquipmentStateT,
  UseEventImageT,
  UpdateWithPhotosProgress,
  UseGasT,
  GraphFilter,
  RequestTokenParams,
  ValidateTokenParams,
  UseSessionT,
  UsePurchaseGraphT,
  UseEmailSignupFormT,
  UseRegistryPurchaseT,
  UseUserAvatarT,
  UseUserT,
  LeakLocation,
  LeakLocationDetail,
  LeakLocationCategory,
}
