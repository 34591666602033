import { useTranslation } from 'react-i18next';

import { useForm, Controller } from 'react-hook-form'

import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'

import {
  useUpdateEventWithPhotos,
} from 'client/src/queries'


import { currentLocalDatetimeString } from '../lib/utils'
import { validImageFileVld, PhotoInputField } from './FormFields'
import EquipmentPicker from './EquipmentPicker'
import LocationPicker from './LocationPicker'
import CreateProgressModal from './CreateProgressModal'

const EquipmentMoveVld = z.object({
  shipmentAt: z.date().max(new Date(), 'Date must be in the past'),
  originId: z.string().min(5, 'origin location required'),
  originPerson: z.string().min(5, 'origin PIC required'),
  destId: z.string().min(5, 'destination location required'),
  destPerson: z.string().min(5, 'destination PIC required'),
  equipmentPhoto: validImageFileVld,
  transportPhoto: validImageFileVld,
  handoverPhoto: validImageFileVld.optional(),
  notes: z.string(),
  equipmentIds: z.array(z.string()).min(1, 'At least one equipment is required').max(20),
})

type CreateEquipmentMoveT = z.infer<typeof EquipmentMoveVld>

function MoveForm() {
  const { t } = useTranslation()

  const { progress, mutate, reset: resetSubmit } = useUpdateEventWithPhotos({
    specialEvents: 'equipmentMove',
  })

  const {
    register, control, handleSubmit, setValue, watch, formState: { errors }, reset: resetForm,
  } = useForm<CreateEquipmentMoveT>({
    resolver: zodResolver(EquipmentMoveVld),
    defaultValues: {
      equipmentIds: [],
    },
  })
  const equipmentIds = watch('equipmentIds')

  return (<>
    <CreateProgressModal progress={progress} mutate={mutate} reset={resetSubmit} resetForm={resetForm} />
    <Row><Col className="mx-auto mb-5" md="8" lg="6">
      <h2>{ t('Equipment Movement') }</h2>

      <p>
        { t('Please fill out this form to record equipmment moving.') }
      </p>

      <Form onSubmit={ handleSubmit((data) => mutate.mutate({ event: data, type: 'move' })) }>

        <Form.Group controlId="shipmentAt" className="mb-3">
          <Form.Label>{ t('Date the equipment was moved') }</Form.Label>
          <Form.Control
            type="datetime-local"
            max={ currentLocalDatetimeString() }
            {...register('shipmentAt', { valueAsDate: true })}
            isInvalid={ 'shipmentAt' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.shipmentAt?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label htmlFor="originId">
            { t('Location where the equipment started.') }
          </Form.Label>
          <Controller
            control={ control }
            name="originId"
            render={
              (
                { field: { onChange, onBlur, value }, fieldState: { invalid, error } }
              ) => (<>
                <LocationPicker
                  id="originId"
                  placeholder={ t('Select location...') }
                  clearButton={ true }
                  onBlur={ onBlur }
                  isInvalid={ invalid }
                  selected={ value ? [value] : [] }
                  onChange={ (selected: string[]) => onChange(selected[0]) }
                />
                <div className={ `invalid-feedback ${ invalid ? 'd-block' : '' }` }>
                  { t(error?.message || 'Error') }
                </div>
              </>)
            }
          />
        </Form.Group>

        <Form.Group controlId="originPerson" className="mb-3">
          <Form.Label>
            { t('Name of the PIC at the origin, and their WA number if needed.') }
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={ t('Enter name') }
            {...register('originPerson')}
            isInvalid={ 'originPerson' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.originPerson?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label htmlFor="destId">
            { t('Location where the equipment is arriving.') }
          </Form.Label>
          <Controller
            control={ control }
            name="destId"
            render={
              (
                { field: { onChange, onBlur, value }, fieldState: { invalid, error } }
              ) => (<>
                <LocationPicker
                  id="destId"
                  placeholder={ t('Select location...') }
                  clearButton={ true }
                  onBlur={ onBlur }
                  isInvalid={ invalid }
                  selected={ value ? [value] : [] }
                  onChange={ (selected: string[]) => onChange(selected[0]) }
                />
                <div className={ `invalid-feedback ${ invalid ? 'd-block' : '' }` }>
                  { t(error?.message || 'Error') }
                </div>
              </>)
            }
          />
        </Form.Group>

        <Form.Group controlId="destPerson" className="mb-3">
          <Form.Label>
            { t('Name of the PIC at the dest, and their WA number if needed.') }
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={ t('Enter name') }
            {...register('destPerson')}
            isInvalid={ 'destPerson' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.destPerson?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="equipmentPhoto" className="mb-3">
          <Form.Label>{ t('Photo of the equipment all together') }</Form.Label>
          <PhotoInputField control={ control } name="equipmentPhoto" />
        </Form.Group>
        <Form.Group controlId="transportPhoto" className="mb-3">
          <Form.Label>{ t('Any tranport documentation (eg surat jalan)') }</Form.Label>
          <PhotoInputField control={ control } name="transportPhoto" />
        </Form.Group>
        <Form.Group controlId="handoverPhoto" className="mb-3">
          <Form.Label>{ t('Equipment handover documentation (if available)') }</Form.Label>
          <PhotoInputField control={ control } name="handoverPhoto" />
        </Form.Group>
        <Form.Group controlId="notes" className="mb-3">
          <Form.Label>
            { t('Additional Note') }
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={ t('Enter notes') }
            {...register('notes')}
            isInvalid={ 'notes' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.notes?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>


        <Form.Group controlId="equipmentIds" className="mb-3">
          <Form.Label>{ t('What equipment is moving?') }</Form.Label>
          <EquipmentPicker
            multiple
            id="equipmentFilter"
            placeholder={ t('Pick equipment...') }
            clearButton={true}
            selected={ equipmentIds }
            onChange={ (ids) => setValue('equipmentIds',ids) }
            isInvalid={'equipmentIds' in errors}
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.equipmentIds?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Button variant="primary" type="submit">
          { t('Submit') }
        </Button>

      </Form>
    </Col></Row>
  </>);
}

export default MoveForm;
