import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useForm, Controller } from 'react-hook-form'

import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'

import { useSearchString } from '../lib/searchState'
import { currentLocalDatetimeString } from '../lib/utils'

import { useUpdateEventWithPhotos } from 'client/src/queries'

import { validImageFileVld, PhotoInputField } from './FormFields'
import CreateProgressModal from './CreateProgressModal'
import EquipmentPicker from './EquipmentPicker'

const CreateVacuumVld = z.object({
  vacuumAt: z.date(),
  equipmentId: z.string().min(5, 'Equipment code is required'),
  pressure: z.number({
    required_error: 'Pressure is required',
    invalid_type_error: 'Pressure must be a number in microns',
  }).positive(),
  notes: z.string(),
  vacuumPhoto: validImageFileVld,
})

type CreateVacuumT = z.infer<typeof CreateVacuumVld>

function VacuumForm() {
  const { t } = useTranslation()

  const [ equipmentId, _setEquipmentId ] = useSearchString('', 'eqId')

  const { progress, mutate, reset: resetSubmit } = useUpdateEventWithPhotos()

  const {
    register, control, handleSubmit, setValue, formState: { errors }, reset: resetForm,
  } = useForm<CreateVacuumT>({
    resolver: zodResolver(CreateVacuumVld),
  })

  useEffect(() => {
    if (equipmentId) {
      setValue('equipmentId', equipmentId, { shouldTouch: true })
    }
  }, [equipmentId, setValue])

  return (<>
    <CreateProgressModal progress={progress} mutate={mutate} reset={resetSubmit} resetForm={resetForm} />
    <Row><Col className="mx-auto" md="8" lg="6">
      <h2>{ t('Vacuum') }</h2>

      <Form onSubmit={ handleSubmit((data) => mutate.mutate({ event: data, type: 'vacuum' })) }>
        <Form.Group controlId="vacuumDate" className="mb-3">
          <Form.Label>{ t('Date the vacuuming was performed') }</Form.Label>
          <Form.Control
            type="datetime-local"
            max={ currentLocalDatetimeString() }
            {...register('vacuumAt', { valueAsDate: true })}
            isInvalid={ 'vacuumAt' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.vacuumAt?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label htmlFor="equipmentId">
            { t('Equipment Code') }
          </Form.Label>
          <Controller
            control={ control }
            name="equipmentId"
            render={
              (
                { field: { onChange, onBlur, value }, fieldState: { invalid, error } }
              ) => (<>
                < EquipmentPicker
                  id="equipmentId"
                  placeholder={ t('Select equipment code...') }
                  clearButton={ true }
                  selected={ value ? [value] : [] }
                  onChange={ (ids: string[]) => onChange(ids[0]) }
                  onBlur={ onBlur }
                  isInvalid={ invalid }
                />
                <div className={ `invalid-feedback ${ invalid ? 'd-block' : '' }` }>
                  { t(error?.message || 'Error') }
                </div>
              </>)
            }
          />
        </Form.Group>

        <Form.Group controlId="status" className="mb-3">
          <Form.Label>{ t('Cylinder Pressure (microns)') }</Form.Label>
          <Form.Control
            {...register('pressure', { valueAsNumber: true }) }
            type="number"
            isInvalid={ 'pressure' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.pressure?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="notes" className="mb-3">
          <Form.Label>
            { t('Additional Note') }
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={ t('Enter notes') }
            {...register('notes')}
            isInvalid={ 'notes' in errors }
          />
          <Form.Control.Feedback type="invalid">
            { t(errors.notes?.message || 'Error') }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="vacuumPhoto" className="mb-3">
          <Form.Label>{ t('Supporting Photo or Video') }</Form.Label>
          <PhotoInputField control={ control } name="vacuumPhoto" />
        </Form.Group>

        <Button variant="primary" type="submit">
          { t('Submit') }
        </Button>
      </Form>
    </Col></Row>
  </>);
}

export default VacuumForm;
